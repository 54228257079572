import { getFetchHeaders } from "..";

export const getMeUpdatePassword = () => `/api/me/update-password`;

interface Props {
    old_password?: string;
    password: string;
    confirm_password: string;
}

export const updatePassword = async (props: Props) => {
    const headers = await getFetchHeaders();
    const result = await fetch(getMeUpdatePassword(), {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(props),
    });

    return await result.json();
};
