import { createContext } from "react";

type InitialDataContextType = {
    values: any;
};

export const InitialDataContext = createContext<InitialDataContextType>({
    values: {},
});

export const InitialDataContextProvider = ({
    values,
    children,
}: {
    values: any;
    children: any;
}) => {
    return (
        <InitialDataContext.Provider
            value={{
                values: values,
            }}
        >
            {children}
        </InitialDataContext.Provider>
    );
};

export default { InitialDataContext, InitialDataContextProvider };
