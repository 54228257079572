import { get, isNil } from "lodash";
import { useRouter } from "next/router";
import useUser from "../hooks/user/useUser";
import UserModel from "../src/Core/Models/User/UserModel";

const Beacon = () => {
    const { user } = useUser<UserModel>({
        options: {
            withModel: true,
        },
    });

    const router = useRouter();

    if (router.route.indexOf("public/client-reports/[md5ClientReport]") >= 0) {
        return null;
    }

    if (isNil(user)) {
        return null;
    }

    return (
        <>
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `window.Beacon('identify', {
                        name: '${user.firstname} ${user.lastname}',
                        email: '${user.email}',
                        Subscription: '${get(user, "plan.name", "")} - ${get(
                        user,
                        "plan.limit_projects",
                        ""
                    )}'
                      })`,
                }}
            />
        </>
    );
};

export default Beacon;
