import { pick } from "lodash";

export enum ROLES {
    USER = "USER",
    ADMIN = "ADMIN",
    SUPPORT = "SUPPORT",
}

export const ROLES_SELECT = [
    {
        value: ROLES.ADMIN,
        label: "Admin",
    },
    {
        value: ROLES.SUPPORT,
        label: "Support",
    },
    {
        value: ROLES.USER,
        label: "User",
    },
];

export enum PERMISSIONS {
    APPLICATION = "application",
    USER = "user",
    PROJECT = "project",
    ADMIN_SUPPORT = "adminsupport",
}

export type PERMISSION_KEY =
    | "ninja"
    | "superninja"
    | "deleteuser"
    | "create"
    | "read"
    | "update"
    | "delete"
    | "view"
    | "oneclickaccess";

interface Permission {
    label: string;
    key: PERMISSION_KEY;
}

const LIST_PERMISSIONS: {
    [key: string]: Permission;
} = {
    ADMIN_NINJA_MODE: {
        label: "Ninja Mode",
        key: "ninja",
    },
    ADMIN_SUPER_NINJA_MODE: {
        label: "Super Ninja Mode (no restriction)",
        key: "superninja",
    },
    ADMIN_DELETE_USER: {
        label: "Delete User",
        key: "deleteuser",
    },
    ADMIN_ONE_CLICK_ACCESS: {
        label: "One Click Access",
        key: "oneclickaccess",
    },
    CREATE: {
        label: "Create",
        key: "create",
    },
    READ: {
        label: "Read",
        key: "read",
    },
    UPDATE: {
        label: "Update",
        key: "update",
    },
    DELETE: {
        label: "Delete",
        key: "delete",
    },
    VIEW: {
        label: "View",
        key: "view",
    },
};

export const PERMISSIONS_ACCESS = {
    [PERMISSIONS.APPLICATION]: [LIST_PERMISSIONS.VIEW.key],
    [PERMISSIONS.USER]: [
        LIST_PERMISSIONS.CREATE.key,
        LIST_PERMISSIONS.READ.key,
        LIST_PERMISSIONS.UPDATE.key,
        LIST_PERMISSIONS.DELETE.key,
    ],
    [PERMISSIONS.PROJECT]: [
        LIST_PERMISSIONS.CREATE.key,
        LIST_PERMISSIONS.READ.key,
        LIST_PERMISSIONS.UPDATE.key,
        LIST_PERMISSIONS.DELETE.key,
    ],
    [PERMISSIONS.ADMIN_SUPPORT]: [
        LIST_PERMISSIONS.ADMIN_SUPER_NINJA_MODE.key,
        LIST_PERMISSIONS.ADMIN_NINJA_MODE.key,
        LIST_PERMISSIONS.ADMIN_DELETE_USER.key,
        LIST_PERMISSIONS.ADMIN_ONE_CLICK_ACCESS.key,
    ],
};

export const PERMISSIONS_SELECT = [
    {
        label: "User",
        key: PERMISSIONS.APPLICATION,
        permissions: Object.values(pick(LIST_PERMISSIONS, "VIEW")),
    },
    {
        label: "Team Support",
        key: PERMISSIONS.ADMIN_SUPPORT,
        permissions: Object.values(
            pick(
                LIST_PERMISSIONS,
                "ADMIN_SUPER_NINJA_MODE",
                "ADMIN_NINJA_MODE",
                "ADMIN_DELETE_USER",
                "ADMIN_ONE_CLICK_ACCESS"
            )
        ),
    },
];
