import { TwoFactorMethod } from ".prisma/client";
import { getMePath } from ".";

interface Props {
    method: TwoFactorMethod;
}

export const updateTwoFactorAuthentification = async (props: Props) => {
    const result = await fetch(getMePath(), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ...props,
            type: "two_factor",
        }),
    });

    return await result.json();
};
