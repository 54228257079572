import {
    FromRegister,
    OrganizationType,
    Prisma,
    SettingsPlan,
    User,
    UserCustomEmailDomain,
    UserStatus,
    UserThirdPartyIntegration,
    WhiteLabel,
} from "@prisma/client";
import { differenceInDays } from "date-fns";
import { difference, find, isNil } from "lodash";
import { createSchema } from "morphism";
import Stripe from "stripe";
import { CustomSendingDomain } from "./CustomSendingDomainModel";

export interface SourceUserModel extends User {
    UserCustomEmailDomain?: Array<UserCustomEmailDomain>;
    CompanyDetails: Array<any>;
    OAuthClient: Array<any>;
    SettingsPlan: Array<SettingsPlan>;
    UserThirdPartyIntegration: Array<UserThirdPartyIntegration>;
    WhiteLabel: WhiteLabel;
    billingInformation: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
    };
    invoice_upcoming: Stripe.Invoice | null;
    plan: SettingsPlan;
    total_hourly_backup: number;
    total_projects: number;
    has_password: boolean;
}

export interface TargetUserModel extends User {
    CompanyDetails: Array<any>;
    OAuthClient: Array<any>;
    UserThirdPartyIntegration: Array<UserThirdPartyIntegration>;
    WhiteLabel: WhiteLabel;
    has_password: boolean;
    billingInformation: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
    };
    search_user_token: string;
    invoice_upcoming: Stripe.Invoice | null;
    plan: SettingsPlan;
    total_hourly_backup: number;
    total_projects: number;
    custom_email_domains?: Array<CustomSendingDomain>;
    integrations?: Array<UserThirdPartyIntegration>;
    subscription: Stripe.Subscription | null;
}

export const SchemaUserModel = createSchema<TargetUserModel, SourceUserModel>({
    CompanyDetails: "CompanyDetails",
    OAuthClient: "OAuthClient",
    UserThirdPartyIntegration: "UserThirdPartyIntegration",
    WhiteLabel: "WhiteLabel",
    billingInformation: "billingInformation",
    invoice_upcoming: "invoice_upcoming",
    plan: "SettingsPlan[0]",
    created_at: "created_at",
    customer_stripe: "customer_stripe",
    email: "email",
    firstname: "firstname",
    id: "id",
    timezone: "timezone",
    has_password: "has_password",
    is_active: "is_active",
    lastname: "lastname",
    newsletters: "newsletters",
    password: "password",
    payment_method: "payment_method",
    from_register: "from_register",
    two_factor_method: "two_factor_method",
    default_settings_project: "default_settings_project",
    updated_at: "updated_at",
    role: "role",
    grants: "grants",
    trial_days: "trial_days",
    date_first_payment: "date_first_payment",
    last_payment_failed: "last_payment_failed",
    deleted_at: "deleted_at",
    whiteLabelId: "whiteLabelId",
    total_hourly_backup: "total_hourly_backup",
    total_projects: "total_projects",
    search_user_token: "search_user_token",
    custom_email_domains: "UserCustomEmailDomain",
    integrations: "UserThirdPartyIntegration",
    organization_type: "organization_type",
    rbac_grants: "rbac_grants",
    accept_support_access: "accept_support_access",
    status: "status",
    subscription: "subscription",
});

class UserModel implements TargetUserModel {
    timezone: string;
    total_hourly_backup: number;
    total_projects: number;
    created_at: Date;
    customer_stripe: string;
    search_user_token: string;
    email: string;
    firstname: string;
    id: number;
    is_active: boolean;
    lastname: string;
    newsletters: boolean;
    password: string;
    has_password: boolean;
    payment_method: string;
    from_register: FromRegister;
    two_factor_method: "EMAIL";
    default_settings_project: Prisma.JsonValue;
    updated_at: Date;
    role: string;
    grants: string[];
    trial_days: number;
    date_first_payment: Date;
    last_payment_failed: boolean;
    deleted_at: Date;
    whiteLabelId: number;
    organization_type: OrganizationType;
    CompanyDetails: Array<any>;
    OAuthClient: Array<any>;
    UserThirdPartyIntegration: Array<UserThirdPartyIntegration>;
    WhiteLabel: WhiteLabel;
    rbac_grants: string[];
    accept_support_access: boolean;
    billingInformation: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
    };
    invoice_upcoming: Stripe.Invoice | null;
    plan: SettingsPlan;
    custom_email_domains?: Array<CustomSendingDomain>;
    integrations?: UserThirdPartyIntegration[];
    status: UserStatus;
    subscription: Stripe.Subscription | null;

    public userIsFree(): boolean {
        return this.plan.slug === "free";
    }

    public userIsTrialPeriod(): boolean {
        const createdAt = this.created_at;
        const now = new Date();

        let diffDayCreatedAt = 0;
        try {
            diffDayCreatedAt = differenceInDays(now, new Date(createdAt));
        } catch (error) {}

        const trialDays = this.trial_days;

        if (diffDayCreatedAt <= trialDays) {
            return true;
        }

        return false;
    }

    getCustomDomain({ domain }: { domain?: string }) {
        return find(this.custom_email_domains, (item) => {
            return item.domain === domain;
        });
    }

    domainIsVerify({ domain }: { domain: string }): boolean {
        const item = this.getCustomDomain({ domain });

        if (isNil(item)) {
            return false;
        }

        if (isNil(item?.verification_data)) {
            return false;
        }

        return item?.verification_data.state !== "unverified";
    }

    getMxRecords({ domain }: { domain: string }) {
        const item = this.getCustomDomain({
            domain,
        });

        if (isNil(item?.verification_data)) {
            return [];
        }

        return item?.verification_data?.receiving_dns_records;
    }

    getSpfRecords({ domain }: { domain: string }): Array<{
        cached: any[];
        name: string;
        record_type: string;
        valid: string;
        value: string;
    }> {
        const item = this.getCustomDomain({ domain });
        if (isNil(item?.verification_data)) {
            return [];
        }

        return (
            item?.verification_data?.sending_dns_records.reduce((acc, item) => {
                if (
                    item.value.includes("include:") &&
                    item.record_type === "TXT"
                ) {
                    acc.push(item);
                }
                return acc;
            }, []) || []
        );
    }

    getOtherRecords({ domain }: { domain: string }): Array<{
        cached: any[];
        name: string;
        record_type: string;
        valid: string;
        value: string;
    }> {
        const item = this.getCustomDomain({ domain });
        const spf = this.getSpfRecords({ domain });
        const dkim = this.getDkimRecords({ domain });

        if ((isNil(spf) && isNil(dkim)) || isNil(item?.verification_data)) {
            return [];
        }

        return difference(item?.verification_data?.sending_dns_records, [
            ...spf,
            ...dkim,
        ]);
    }

    getDkimRecords({ domain }: { domain: string }): Array<{
        cached: string[];
        name: string;
        record_type: string;
        valid: string;
        value: string;
    }> {
        const item = this.getCustomDomain({ domain });

        if (isNil(item?.verification_data)) {
            return [];
        }

        return (
            item?.verification_data?.sending_dns_records.reduce((acc, item) => {
                if (
                    item.value.includes("k=rsa;") &&
                    item.record_type === "TXT"
                ) {
                    acc.push(item);
                }
                return acc;
            }, []) || []
        );
    }

    getCnameRecords({ domain }: { domain: string }): Array<{
        cached: string[];
        name: string;
        record_type: string;
        valid: string;
        value: string;
    }> {
        const item = this.getCustomDomain({ domain });

        return (
            item?.verification_data?.sending_dns_records.reduce((acc, item) => {
                if (item.record_type === "CNAME") {
                    acc.push(item);
                }
                return acc;
            }, []) || []
        );
    }

    haveIntegrations(): boolean {
        if (isNil(this.integrations)) {
            return false;
        }
        return this.integrations.length > 0;
    }

    haveCustomEmailDomains(): boolean {
        if (isNil(this.custom_email_domains)) {
            return false;
        }
        return this.custom_email_domains.length > 0;
    }
}

export default UserModel;
