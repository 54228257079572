import { getCsrfToken } from "next-auth/react";

interface Props {
    withCsrfToken?: boolean;
}

export const getFetchHeaders = async (
    { withCsrfToken = true }: Props = {
        withCsrfToken: true,
    }
) => {
    const headers = {
        "Content-Type": "application/json",
    };

    if (withCsrfToken) {
        headers["X-CSRF-Token"] = await getCsrfToken();
    }

    return headers;
};
