import useSWR from "swr";
import { get, isNil } from "lodash";
import { getMePath } from "../../src/FetchApi/Me";
import { User } from "../../src/Types/User/User";
import qs from "query-string";
import UserModel, {
    SchemaUserModel,
} from "../../src/Core/Models/User/UserModel";
import { morphism } from "morphism";
import { MeDetailsQuery } from "../../src/Core/Models/User/UserDetailsModel";
import { useSession } from "next-auth/react";
import useApiKey from "./useApiKey";

interface Props {
    suspense?: boolean;
    params?: MeDetailsQuery;
    options?: {
        withModel?: boolean;
    };
}

interface Response<T> {
    data: any | null;
    user: T | null;
    isAdminLogInAs: {
        user: User | null;
    };
    mainUser: User | null;
    mutate: Function;
    isMainAccount: boolean;
    token: {
        access_token: string;
    };
    isLoading: boolean;
}

function useUser<T>(
    { suspense, params, options }: Props = { suspense: true }
): Response<T> {
    if (isNil(suspense)) {
        suspense = true;
    }

    const { data: session } = useSession();
    const apiKey = useApiKey();

    let path = getMePath();
    if (!isNil(params)) {
        path = qs.stringifyUrl({
            url: path,
            query: params,
        });
    }

    const { data, mutate, ...rest } = useSWR(path, {
        suspense: suspense,
    });

    if (!data) {
        return {
            user: null,
            isAdminLogInAs: null,
            mainUser: null,
            token: null,
            data: null,
            isMainAccount: false,
            mutate,
            ...rest,
        };
    }

    let user = get(data, "user", null);
    if (!isNil(user) && !isNil(options) && options.withModel) {
        user = morphism(SchemaUserModel, user, UserModel);
    }

    const isAdminLogInAs = get(session, "user.isAdmin", null);
    let mainUser = get(session, "user.mainUser", null);
    if (!isNil(user)) {
        mainUser = get(data, "mainUser", null);
    }

    return {
        mutate,
        data: data,
        user: user,
        isAdminLogInAs: isAdminLogInAs,
        mainUser: mainUser || user,
        isMainAccount: isNil(mainUser),
        token: {
            access_token: apiKey,
        },
        ...rest,
    };
}

export default useUser;
